import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ChannelCards } from '../slices/ChannelCards'
import { Text } from '../slices/Text'
import { AdSlotSlice } from '../slices/AdSlot'

const FrontPage = ({ data }) => {
    const doc = data.prismicFrontPage?.data

    return (
        <Layout banner={doc}>
            <Seo
                title={
                    doc?.seo_title?.text ||
                    prismicH.asText(doc?.page_title?.richText)
                }
                description={doc?.seo_description?.text}
                ogImage={doc?.og_image?.url}
            />
            <div className="mx-auto px-6 pt-5 pb-10 max-w-7xl" id="content">
                <SliceZone
                    slices={doc.body}
                    components={{
                        channel_cards: ChannelCards,
                        text: Text,
                        ad_slot: AdSlotSlice
                    }}
                    context={{
                        type: 'live'
                    }}
                />
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        prismicFrontPage {
            data {
                page_title {
                    richText
                }
                page_description {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                og_image {
                    url
                }
                body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ...FrontPageDataBodyChannelCards
                    ...FrontPageDataBodyText
                    ...FrontPageDataBodyAdSlot
                }
            }
        }
    }
`

export default FrontPage
